/* eslint-disable @next/next/no-img-element */

import React, { useState, useRef } from 'react';
import Link from 'next/link'

import { event } from '../../lib/gtag';

import SubMenuItems from '../SubMenuItems';

import { 
  vehicleItems, qaVehicleItems,
  helpItems, qaHelpItems,
  toolsItems, qaToolsItems, servicesItems, qaServicesItems,
  boiVehicleItems,
  boiHelpItems,
  boiServicesItems
} from '../SubMenuItems/menuItems'

import styles from './styles.module.scss'
import RightBlackArrow from '../../public/assets/icons/right-arrow-black.svg'
import RightBlackArrowBoi from '../../public/assets/nevo-boi/chevron-right.svg'
import { useIsBoiWebsite } from "../Context/WebsiteType";

const SideMenuNav = ({ hideMenu, renderSideMenuHeader } : any) => {
  const [showVehicle, setVehiclesItems] = useState(false);
  const [showHelp, setHelpItems] = useState(false);
  const [showTools, setToolsItems] = useState(false);
  const [showServices, setServicesItems] = useState(false);

  const refItems = useRef<HTMLDivElement>(null);

  const displayItems = showVehicle || showHelp || showTools || showServices;

  const isBoiWebsite = useIsBoiWebsite();

  const [VehicleItems]: any = SubMenuItems({ items: isBoiWebsite ? boiVehicleItems : vehicleItems, qaItems: qaVehicleItems, eventType: 'SideMenu' });
  const [HelpItems]: any = SubMenuItems({ items: isBoiWebsite ? boiHelpItems : helpItems, qaItems: qaHelpItems, eventType: 'SideMenu' });
  const [ToolsItems]: any = SubMenuItems({ items: toolsItems, qaItems: qaToolsItems, eventType: 'SideMenu' });
  const [ServicesItems]: any = SubMenuItems({ items: isBoiWebsite? boiServicesItems : servicesItems, qaItems: qaServicesItems, eventType: 'SideMenu' });

  const hideItems = () => {
    if (refItems && refItems.current) {
      refItems.current.classList.toggle(isBoiWebsite ? styles.itemsMenuHideBoi : styles.itemsMenuHide);
    }

    setTimeout(() => {
      if (refItems && refItems.current) {
        refItems.current.style.display = "none";
      }

      setVehiclesItems(false);
      setHelpItems(false);
      setToolsItems(false);
      setServicesItems(false);
    }, 475);
  }

  const onNavItemClick = (action: string) => {
    event(action)
    hideMenu()
  }

  return (
    <>
      <div className={isBoiWebsite ? styles.sideMenuNavBoi : styles.sideMenuNav}>
        <div className="nav-button" onClick={() => {setVehiclesItems(true)}}>
          Browse Vehicles <img src={isBoiWebsite ? RightBlackArrowBoi.src : RightBlackArrow.src} alt="right arrow"/>
        </div>

        <div className="nav-button" onClick={() => {setHelpItems(true)}}>
          Help & Advice <img src={isBoiWebsite ? RightBlackArrowBoi.src : RightBlackArrow.src} alt="right arrow"/>
        </div>

        <div className="nav-button" onClick={() => {setToolsItems(true)}}>
          Tools <img src={isBoiWebsite ? RightBlackArrowBoi.src : RightBlackArrow.src} alt="right arrow"/>
        </div>

        <div className="nav-button" onClick={() => {setServicesItems(true)}}>
          Services <img src={isBoiWebsite ? RightBlackArrowBoi.src : RightBlackArrow.src} alt="right arrow"/>
        </div>
      </div>

      { displayItems &&
        <div className={isBoiWebsite ? styles.itemsMenuBoi : styles.itemsMenu} ref={refItems}>
          <div className={isBoiWebsite ? styles.itemsHolderBoi : styles.itemsHolder}>
            { renderSideMenuHeader() }

            <div onClick={hideItems} className={isBoiWebsite ? styles.goBackHolderBoi : styles.goBackHolder}>
              <img src={isBoiWebsite ? RightBlackArrowBoi.src : RightBlackArrow.src} alt="right arrow"/>
            </div>
            
            { showVehicle && 
              <>
                <Link href="/vehicles/all" passHref>
                  <p onClick={() => onNavItemClick('vehiclesSideMenu')} className={isBoiWebsite ? styles.leadingNavButtonBoi : styles.leadingNavButton}>
                    Browse Vehicles
                  </p>
                </Link>
                { VehicleItems }
              </> 
            }
            { showHelp && 
              <>
                <Link href="/learn/ev-education" passHref>
                  <p onClick={() => onNavItemClick('helpSideMenu')} className={isBoiWebsite ? styles.leadingNavButtonBoi : styles.leadingNavButton}>
                    Help & Advice
                  </p>
                </Link>
                { HelpItems }
              </> 
            }
            { showTools && 
              <>
                <p className={`${isBoiWebsite ? styles.leadingNavButtonBoi : styles.leadingNavButton} default-cursor`}>
                  Tools
                </p>
                { ToolsItems }
              </> 
            }
            { showServices && 
              <>
                <p className={`${isBoiWebsite ? styles.leadingNavButtonBoi : styles.leadingNavButton} default-cursor`}>
                  Services
                </p>
                { ServicesItems }
              </> 
            }
          </div>
        </div>
      }
    </>
    
  )
};

export default SideMenuNav;