/* eslint-disable @next/next/no-img-element */

import React from "react";
import Link from "next/link";

import { event } from "../../lib/gtag";

import SocialMedia from "../SocialMedia";
import Newsletter from "../Newsletter";
import SubMenuItems from "../SubMenuItems";

import BankOfIrelandLogo from "../../public/assets/nevo-boi/logo-large.svg";

import {
	vehicleItems,
	qaVehicleItems,
	helpItems,
	qaHelpItems,
	toolsItems,
	qaToolsItems,
	companyItems,
	qaCompanyItems,
	servicesItems,
	qaServicesItems,
	boiVehicleItems,
	boiHelpItems,
	boiServicesItems,
	boiCompanyItems
} from "../SubMenuItems/menuItems";

import styles from "./styles.module.scss";
import { useIsBoiWebsite } from "../Context/WebsiteType";
import { copyrightDisplayText } from "../../helper/functions/copyrightDisplayText";

type FooterCompanyItem = {
	label: string;
	link: string;
};

const Footer = () => {
	const isBoiWebsite = useIsBoiWebsite();

	const [VehicleItems]: any = SubMenuItems({ items: isBoiWebsite ? boiVehicleItems : vehicleItems, qaItems: qaVehicleItems, eventType: "Footer" });
	const [LearnItems]: any = SubMenuItems({ items: isBoiWebsite ? boiHelpItems : helpItems, qaItems: qaHelpItems, eventType: "Footer" });
	const [ToolsItems]: any = SubMenuItems({ items: toolsItems, qaItems: qaToolsItems, eventType: "Footer" });
	const [ServicesItems]: any = SubMenuItems({ items: isBoiWebsite ? boiServicesItems : servicesItems, qaItems: qaServicesItems, eventType: "Footer" });
	const [CompanyItems]: any = SubMenuItems({ items: isBoiWebsite ? boiCompanyItems : companyItems, qaItems: qaCompanyItems, eventType: "Footer" });
	const FooterCompanyItems: FooterCompanyItem[] = [
		{ label: "Privacy", link: "/privacy" },
		{ label: "Terms", link: "/terms" },
		{ label: "Cookies", link: "/cookies" }
	];

	return (
		<div className={`${isBoiWebsite ? styles.footerBoi : styles.footer} ${isBoiWebsite ? styles.dark : styles.light}`}>
			<div className={isBoiWebsite ? styles.footerContainerBoi : styles.footerContainer}>
				{isBoiWebsite && (
					<div className={styles.logoHolderBoi}>
						<img className={styles.logoBoi} src={BankOfIrelandLogo.src} alt="BOI Logo" />
					</div>
				)}

				<div className={isBoiWebsite ? styles.footHolderBoi : styles.footHolder}>
					<div className={isBoiWebsite ? styles.holderContainerBoi : styles.holderContainer}>
						<div className={isBoiWebsite ? styles.footerNavsHolderBoi : styles.footerNavsHolder}>
							<div className={`${isBoiWebsite ? styles.footerNavColumnBoi : styles.footerNavColumn} ${isBoiWebsite ? styles.dark : styles.light}`}>
								<h1 className={isBoiWebsite ? styles.footerleadingNavBoi : styles.footerleadingNav} onClick={() => event("vehiclesFooter")}>
									<Link href="/vehicles/all">Browse Vehicles</Link>
								</h1>

								<div>{VehicleItems}</div>
							</div>

							<div className={`${isBoiWebsite ? styles.footerNavColumnBoi : styles.footerNavColumn} ${isBoiWebsite ? styles.dark : styles.light}`}>
								<h1 className={isBoiWebsite ? styles.footerleadingNavBoi : styles.footerleadingNav} onClick={() => event("helpFooter")}>
									<Link href="/learn/ev-education">Help & Advice</Link>
								</h1>

								<div>{LearnItems}</div>
							</div>

							<div className={`${isBoiWebsite ? styles.footerNavColumnBoi : styles.footerNavColumn} ${isBoiWebsite ? styles.dark : styles.light}`}>
								<h1 className={`${isBoiWebsite ? styles.footerleadingNavBoi : styles.footerleadingNav} default-cursor`}>Tools</h1>

								<div>{ToolsItems}</div>
							</div>

							<div className={`${isBoiWebsite ? styles.footerNavColumnBoi : styles.footerNavColumn} ${isBoiWebsite ? styles.dark : styles.light}`}>
								<h1 className={`${isBoiWebsite ? styles.footerleadingNavBoi : styles.footerleadingNav} default-cursor`}>Services</h1>

								<div>{ServicesItems}</div>
							</div>

							<div className={`${isBoiWebsite ? styles.footerNavColumnBoi : styles.footerNavColumn} ${isBoiWebsite ? styles.dark : styles.light}`}>
								<h1 className={`${isBoiWebsite ? styles.footerleadingNavBoi : styles.footerleadingNav} + " " + ${isBoiWebsite ? styles.companyNavBoi : styles.companyNav}`}>
									{isBoiWebsite ? "About Nevo" : "Company"}
								</h1>
								<div>{CompanyItems}</div>
							</div>

							{!isBoiWebsite && (
								<div className={styles.footerNavColumn}>
									<h1 className={styles.footerleadingNav + " " + styles.nevoChargingNav}>
										<a onClick={() => event("nevoAdvisoryFooter")} href="https://nevoadvisory.ie" target="_blank" rel="noreferrer">
											Nevo Advisory
										</a>
									</h1>
								</div>
							)}
						</div>
						<div className={isBoiWebsite ? styles.newsletterAndFollowBoi : styles.newsletterAndFollow}>
							<div className={`${isBoiWebsite ? styles.newsletterHolderBoi : styles.newsletterHolder} ${isBoiWebsite ? styles.dark : styles.light}`}>
								<Newsletter whiteVersion />
							</div>

							{!isBoiWebsite && (
								<div className={isBoiWebsite ? styles.followHolderBoi : styles.followHolder}>
									<SocialMedia whiteVersion={true} />
								</div>
							)}
						</div>
					</div>
					{isBoiWebsite && (
						<div className={styles.footInfoBoi}>
							<p>
								By using this service, you will be accessing a third party website. Bank of Ireland (BOI) has taken steps to ensure the accuracy of BOI specific information but takes
								no responsibility for the accuracy of all other information. Please be advised Bank of Ireland will not supply your data to a third party. For Bank of Ireland’s privacy
								notice, please click here{" "}
								<Link href="/privacy">
									<a className={styles.inlineLink}>
										click here
									</a>
								</Link>
								.
							</p>
						</div>
					)}
				</div>

				{isBoiWebsite && (
					<div className={styles.footerPrivacyHolderBoi}>
						<div className={styles.footerLinksBoi}>
							{Array.isArray(FooterCompanyItems) && FooterCompanyItems.length > 0 ? (
								FooterCompanyItems.map((item: any, index: number) => (
									<React.Fragment key={index}>
										<a href={item.link}>{item.label}</a>
										{index < FooterCompanyItems.length - 1 && <span className={styles.separator}>|</span>}
									</React.Fragment>
								))
							) : (
								<span />
							)}
						</div>
					</div>
				)}
				<div className={isBoiWebsite ? styles.footerSeparatorBoi : styles.footerSeparator}></div>
				<div className={isBoiWebsite ? styles.copyrightHolderBoi : styles.copyrightHolder}>
					<p className="copyright-mark">{copyrightDisplayText(isBoiWebsite)}</p>
				</div>
			</div>
		</div>
	);
};

export default Footer;
