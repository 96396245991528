import React, { useRef, useState, useEffect, useCallback, Fragment } from "react";

import Link from "next/link";
import { useRouter } from "next/router";

import useMobile from "../Hooks/Mobile";
import useDevice from "../Hooks/Device";
import { event } from "../../lib/gtag";

import SideMenu from "../SideMenu";
import SubMenuItems from "../SubMenuItems";
import EVShowBanner from "../EVShowBanner";

import {
	vehicleItems,
	qaVehicleItems,
	helpItems,
	qaHelpItems,
	toolsItems,
	qaToolsItems,
	servicesItems,
	qaServicesItems,
	boiVehicleItems,
	boiHelpItems,
	boiServicesItems
} from "../SubMenuItems/menuItems";

import styles from "./styles.module.scss";
import Hamburger from "../../public/assets/icons/hamburger";
import { useIsBoiWebsite } from "../Context/WebsiteType";
import BoiHeader from "../BoiHeader";
import NevoLogo from "../../public/assets/images/NevoLogo";

const headerHeights = [
	{ big: 153, small: 132 }, // 1 item
	{ big: 197, small: 169 }, // 2 items
	{ big: 236, small: 203 }, // 3 items
	{ big: 284, small: 243 }, // 4 items
	{ big: 329, small: 280 }, // 5 items
	{ big: 369, small: 317 } // 6 items
];

// boolean:
// transparent - removes the backround
// black - fades the background with black color
// blackStyle - blackFont leaves the font black in any case
interface IHeader {
	transparent?: boolean;
	noShadowBox?: boolean;
	black?: boolean;
	blackStyle?: boolean;
	renderHeader?: boolean;
	hideBanner?: boolean;
}

const Header = ({ transparent = false, noShadowBox = false, black = false, blackStyle = false, renderHeader = true, hideBanner }: IHeader) => {
	const [displayMenu, setDisplayMenu] = useState(false);

	const [headerAnimation, setHeaderAnimation] = useState(false);
	const [fixedHeader, setFixedHeader] = useState(false);
	const [prevScrollY, setPrevScrollY] = useState(0);
	const [hideHeader, setHideHeader] = useState(false);

	const [displaySubVehicles, setDisplaySubVehicles] = useState(false);
	const [displaySubHelp, setDisplaySubHelp] = useState(false);
	const [displaySubTools, setDisplaySubTools] = useState(false);
	const [displaySubServices, setDisplaySubServices] = useState(false);
	const [displayAboutNevo, setDisplayAboutNevo] = useState(false);

	const router = useRouter();
	const mobile = useMobile(1120);
	const device = useDevice();

	const headerRef = useRef<HTMLDivElement>(null);

	const currentPath = router.asPath.split("?")[0];

	const isBoiWebsite = useIsBoiWebsite();

	const [VehicleItems, vehiclesCount]: any = SubMenuItems({ items: isBoiWebsite ? boiVehicleItems : vehicleItems, qaItems: qaVehicleItems, eventType: "Header" });
	const [HelpItems, helpCount]: any = SubMenuItems({ items: isBoiWebsite ? boiHelpItems : helpItems, qaItems: qaHelpItems, eventType: "Header" });
	const [ToolsItems, toolsCount]: any = SubMenuItems({ items: toolsItems, qaItems: qaToolsItems, eventType: "Header" });
	const [ServicesItems, servicesCount]: any = SubMenuItems({ items: isBoiWebsite ? boiServicesItems : servicesItems, qaItems: qaServicesItems, eventType: "Header" });

	const [isSelected, setIsSelected] = useState("");

	// Adapt Header Height on Hover
	const adaptHeaderHeight = useCallback(() => {
		if (headerRef && headerRef.current) {
			let targetHeight;

			const size = window.innerWidth > 1440 ? "big" : "small";

			targetHeight = displaySubTools
				? headerHeights[toolsCount][size]
				: displaySubHelp
				? headerHeights[helpCount][size]
				: displaySubVehicles
				? headerHeights[vehiclesCount][size]
				: displaySubServices
				? headerHeights[servicesCount][size]
				: "";

			if (typeof targetHeight === "number") headerRef.current.style.height = `${targetHeight}px`;
			else headerRef.current.style.height = "";
		}
	}, [displaySubTools, toolsCount, displaySubHelp, helpCount, displaySubVehicles, vehiclesCount, displaySubServices, servicesCount]);

	useEffect(() => {
		adaptHeaderHeight();

		window.addEventListener("resize", adaptHeaderHeight);
		return () => window.removeEventListener("resize", adaptHeaderHeight);
	}, [adaptHeaderHeight]);
	// End of Adaptation

	// Fix and hide header on scroll
	const fixHeader = useCallback(() => {
		const scrollY = window.scrollY;

		// Adding Fixed Header Style
		if (scrollY === 0 && fixedHeader) {
			setFixedHeader(false);
		} else if (scrollY > 30 && !fixedHeader) setFixedHeader(true);

		// Hiding/Showing Header

		if (!headerAnimation) {
			if ((prevScrollY > scrollY || scrollY === 0) && hideHeader) {
				setHeaderAnimation(true);

				setTimeout(() => {
					headerRef?.current?.classList.remove(isBoiWebsite ? styles.showHeaderBoi : styles.showHeader);
					setHideHeader(false);

					setHeaderAnimation(false);
				}, 490);

				headerRef?.current?.classList.add(isBoiWebsite ? styles.showHeaderBoi : styles.showHeader);
			} else if (prevScrollY < scrollY && scrollY > 100 && !hideHeader) {
				setHeaderAnimation(true);

				setTimeout(() => {
					headerRef?.current?.classList.remove(isBoiWebsite ? styles.hideHeaderBoi : styles.hideHeader);
					setHideHeader(true);

					setHeaderAnimation(false);
				}, 490);

				headerRef?.current?.classList.add(isBoiWebsite ? styles.hideHeaderBoi : styles.hideHeader);
			}
		}

		setPrevScrollY(scrollY);
	}, [fixedHeader, headerAnimation, hideHeader, prevScrollY, isBoiWebsite]);

	useEffect(() => {
		fixHeader();
		window.addEventListener("scroll", fixHeader);
		return () => window.removeEventListener("scroll", fixHeader);
	}, [fixHeader]);

	const isDisplaySubNav = displaySubVehicles || displaySubHelp || displaySubTools || displaySubServices || displayAboutNevo;

	useEffect(() => {
		const htmlDoc = document.children[0] as HTMLElement;

		if (isDisplaySubNav) {
			htmlDoc.style.overflow = "hidden";
			document.body.style.overflow = "hidden";
		} else {
			htmlDoc.style.overflow = "";
			document.body.style.overflow = "";
		}

		return () => {
			htmlDoc.style.overflow = "";
			document.body.style.overflow = "";
		};
	}, [isDisplaySubNav]);

	const openSideMenu = () => {
		const htmlDoc = document.children[0] as HTMLElement;
		htmlDoc.style.overflow = "hidden";
		document.body.style.overflow = "hidden";
		event("clickedOnHamburgerMenu");
		setDisplayMenu(true);
	};

	const handleClick = useCallback(
		(linkName: string, eventName: string): void => {
			setIsSelected(linkName);
			event(eventName);
		},
		[setIsSelected]
	);

	const isItemSelected = useCallback(
		(itemName: string) => {
			return isSelected === itemName;
		},
		[isSelected]
	);

	const hideSubNav = () => {
		setDisplaySubVehicles(false);
		setDisplaySubHelp(false);
		setDisplaySubTools(false);
		setDisplaySubServices(false);
		setDisplayAboutNevo(false);
	};

	const headerStyle =
		`${styles.headerContainer}` +
		(fixedHeader ? ` ${styles.fixedHeader}` : "") +
		(isDisplaySubNav ? ` ${styles.headerContainerOpenned}` : "") +
		(transparent ? ` ${styles.transparentHeader}` : "") +
		(black ? ` ${styles.blackHeader}` : "") +
		(blackStyle ? ` ${styles.blackStyle}` : "");

	const headerStyleBoi =
		`${styles.headerContainerBoi}` +
		(fixedHeader ? ` ${styles.fixedHeaderBoi}` : "") +
		(isDisplaySubNav ? ` ${styles.headerContainerOpennedBoi}` : "") +
		(transparent ? ` ${styles.fixedHeaderBoi}` : "") +
		(black ? ` ${styles.blackHeaderBoi}` : "") +
		(blackStyle ? ` ${styles.blackStyleBoi}` : "");

	const renderNavButtons = () => {
		return (
			<Fragment>
				<div className={`${isBoiWebsite ? styles.navButtonBoi : styles.navButton}`}>
					<span
						className="nav-button"
						onMouseEnter={() => {
							hideSubNav();
							setDisplaySubVehicles(true);
						}}
						onClick={() => event("vehiclesHeader")}
					>
						<Link href="/vehicles/all">Browse Vehicles</Link>
					</span>

					{
						<p
							className={
								`${isBoiWebsite ? styles.coolUnderlineBoi : styles.coolUnderline} ` +
								((currentPath.match("vehicle") && !currentPath.match("finance|comparison") && !isDisplaySubNav) || displaySubVehicles
									? isBoiWebsite
										? styles.displayUnderlineBoi
										: styles.displayUnderline
									: "")
							}
						>
							&nbsp;
						</p>
					}

					{displaySubVehicles && <div className={isBoiWebsite ? styles.subNavButtonHolderBoi : styles.subNavButtonHolder}>{VehicleItems}</div>}
				</div>

				<div className={`${isBoiWebsite ? styles.navButtonBoi : styles.navButton}`}>
					<span
						className="nav-button"
						onMouseEnter={() => {
							hideSubNav();
							setDisplaySubHelp(true);
						}}
						onClick={() => event("helpHeader")}
					>
						<Link href="/learn/ev-education">Help & Advice</Link>
					</span>

					{
						<p
							className={
								`${isBoiWebsite ? styles.coolUnderlineBoi : styles.coolUnderline} ` +
								((currentPath.match("learn|post|news") && !isDisplaySubNav) || displaySubHelp ? (isBoiWebsite ? styles.displayUnderlineBoi : styles.displayUnderline) : "")
							}
						>
							&nbsp;
						</p>
					}

					{displaySubHelp && <div className={isBoiWebsite ? styles.subNavButtonHolderBoi : styles.subNavButtonHolder}>{HelpItems}</div>}
				</div>

				<div className={`${isBoiWebsite ? styles.navButtonBoi : styles.navButton}`}>
					<span
						className="nav-button"
						onMouseEnter={() => {
							hideSubNav();
							setDisplaySubTools(true);
						}}
					>
						Tools
					</span>

					{
						<p
							className={
								`${isBoiWebsite ? styles.coolUnderlineBoi : styles.coolUnderline} ` +
								((currentPath.match("journeys|comparison") && !isDisplaySubNav) || displaySubTools ? (isBoiWebsite ? styles.displayUnderlineBoi : styles.displayUnderline) : "")
							}
						>
							&nbsp;
						</p>
					}

					{displaySubTools && <div className={`${isBoiWebsite ? styles.subNavButtonHolderBoi : styles.subNavButtonHolder}`}>{ToolsItems}</div>}
				</div>

				<div className={`${isBoiWebsite ? styles.navButtonBoi : styles.navButton}`}>
					<span
						className="nav-button"
						onMouseEnter={() => {
							hideSubNav();
							setDisplaySubServices(true);
						}}
					>
						Services
					</span>

					{
						<p
							className={
								`${isBoiWebsite ? styles.coolUnderlineBoi : styles.coolUnderline} ` +
								((currentPath.match("finance|bank-of-ireland") && !isDisplaySubNav) || displaySubServices
									? `${isBoiWebsite ? styles.displayUnderlineBoi : styles.displayUnderline}`
									: "")
							}
						>
							&nbsp;
						</p>
					}

					{displaySubServices && <div className={`${isBoiWebsite ? styles.subNavButtonHolderBoi : styles.subNavButtonHolder}`}>{ServicesItems}</div>}
				</div>

				{/* Nevo Advisory */}
				{!isBoiWebsite && (
					<div className={styles.navButton + " " + styles.navCharging}>
						<span className={`nav-button ${styles.navChargingSpan}`}>
							<a className={styles.navChargingA} onClick={() => event("nevoAdvisoryHeader")} href="https://nevoadvisory.ie" target="_blank" rel="noreferrer">
								Nevo Advisory
							</a>
						</span>
					</div>
				)}

				{isBoiWebsite && (
					<div className={`${isBoiWebsite ? styles.navButtonBoi : styles.navButton}`}>
						<span
							className="nav-button"
							onMouseEnter={() => {
								hideSubNav();
								setDisplayAboutNevo(true);
							}}
							onClick={() => handleClick("aboutNevo", "ourMissionHeader")}
						>
							<Link href="/our-mission">About Nevo</Link>
						</span>
						{<p className={`${styles.coolUnderlineBoi} ` + ((currentPath.match("our-mission") && !isDisplaySubNav) || displayAboutNevo ? `${styles.displayUnderlineBoi}` : "")}>&nbsp;</p>}
					</div>
				)}
			</Fragment>
		);
	};

	if (!renderHeader) return <Fragment></Fragment>;

	return (
		<Fragment>
			{isBoiWebsite && (
				<div className={styles.containerBoi}>
					<div className={styles.bodyBoi}>{<BoiHeader />}</div>
				</div>
			)}
			<div>
				<div
					ref={headerRef}
					className={isBoiWebsite ? headerStyleBoi : headerStyle}
					onMouseLeave={() => {
						hideSubNav();
					}}
					style={{
						display: hideHeader ? "none" : "",
						boxShadow: noShadowBox ? "none" : ""
					}}
				>
					{!isBoiWebsite && (
						<Link href="/" passHref>
							<div
								className={"nevo-logo " + styles.nevoLogo}
								onMouseEnter={() => {
									hideSubNav();
								}}
							>
								<NevoLogo color={isDisplaySubNav || fixedHeader ? "#1A1C1F" : blackStyle ? "#1A1C1F" : transparent ? "white" : "#1A1C1F"} />
							</div>
						</Link>
					)}

					{mobile === false && device === "Desktop" && <nav className={isBoiWebsite ? styles.desktopNavBoi : styles.desktopNav}>{renderNavButtons()}</nav>}

					{isBoiWebsite ? (
						!mobile && (
							<div className={styles.hamburgerHolder}>
								<div className={styles.hamburgerIcon} onMouseEnter={hideSubNav} onClick={openSideMenu}>
									<Hamburger color="#0000FF" />
								</div>
							</div>
						)
					) : (
						<div className={styles.hamburgerHolder}>
							<div className={styles.hamburgerIcon} onMouseEnter={hideSubNav} onClick={openSideMenu}>
								<Hamburger color={isDisplaySubNav || fixedHeader ? "#1A1C1F" : blackStyle ? "#1A1C1F" : transparent ? "white" : "#1A1C1F"} />
							</div>
						</div>
					)}
				</div>
				{/* {!hideBanner && (
					<div className={isBoiWebsite ? styles.evShowBanner : ""}>
						<EVShowBanner />
					</div>
				)} */}
			</div>

			{/* {!hideBanner && <EVShowBanner />} */}

			{isDisplaySubNav && <div className="fading-cover">&nbsp;</div>}

			{displayMenu && <SideMenu hideMenu={() => setDisplayMenu(false)} />}
		</Fragment>
	);
};

export default Header;
