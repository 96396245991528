/* eslint-disable @next/next/no-img-element */

import React, { useEffect, useState } from "react";
import Link from "next/link";

import styles from "./styles.module.scss";
import NevoBoiLogoSmall from "../../public/assets/nevo-boi/logo-small.svg";
import backArrow from "../../public/assets/nevo-boi/chevron-left.svg";
import Hamburger from "../../public/assets/icons/hamburger";
import { event } from "../../lib/gtag";
import SubMenuItems from "../SubMenuItems";
import { boiHelpItems, boiServicesItems, boiVehicleItems, qaHelpItems, qaServicesItems, qaToolsItems, qaVehicleItems, toolsItems } from "../SubMenuItems/menuItems";
import SideMenu from "../SideMenu";
import useMobile from "../Hooks/Mobile";

interface BoiHeaderProps {
	children?: React.ReactNode;
	isDisplaySubNav?: boolean;
	fixedHeader?: boolean;
	blackStyle?: boolean;
	transparent?: boolean;
}

const BoiHeader: React.FC<BoiHeaderProps> = ({ children, isDisplaySubNav = false, fixedHeader = false, blackStyle = false, transparent = false }) => {
	const [displayMenu, setDisplayMenu] = useState(false);

	const [displaySubVehicles, setDisplaySubVehicles] = useState(false);
	const [displaySubHelp, setDisplaySubHelp] = useState(false);
	const [displaySubTools, setDisplaySubTools] = useState(false);
	const [displaySubServices, setDisplaySubServices] = useState(false);
	const [displayAboutNevo, setDisplayAboutNevo] = useState(false);

	const [VehicleItems, vehiclesCount]: any = SubMenuItems({ items: boiVehicleItems, qaItems: qaVehicleItems, eventType: "Header" });
	const [HelpItems, helpCount]: any = SubMenuItems({ items: boiHelpItems, qaItems: qaHelpItems, eventType: "Header" });
	const [ToolsItems, toolsCount]: any = SubMenuItems({ items: toolsItems, qaItems: qaToolsItems, eventType: "Header" });
	const [ServicesItems, servicesCount]: any = SubMenuItems({ items: boiServicesItems, qaItems: qaServicesItems, eventType: "Header" });

	const mobile = useMobile(1120);

	const openSideMenu = () => {
		const htmlDoc = document.children[0] as HTMLElement;
		htmlDoc.style.overflow = "hidden";
		document.body.style.overflow = "hidden";
		event("clickedOnHamburgerMenu");
		setDisplayMenu(true);
	};

	const hideSubNav = () => {
		setDisplaySubVehicles(false);
		setDisplaySubHelp(false);
		setDisplaySubTools(false);
		setDisplaySubServices(false);
		setDisplayAboutNevo(false);
	};

	return (
		<header className={styles.fixedHeader}>
			<div className={styles.topBar}></div>
			<nav className={styles.container}>
				<a href="https://personalbanking.bankofireland.com/" target="_blank" rel="noopener noreferrer" className={styles.backContainer}>
					<img className={styles.backArrow} src={backArrow.src} alt="Back" />
					<p className={styles.backButtonText}>Back to Bank of Ireland</p>
				</a>
				<Link href="/" passHref>
					<div className={styles.logoContainer}>
						<img className={styles.logo} src={NevoBoiLogoSmall.src} alt="BOI Logo" />
					</div>
				</Link>
				{mobile && (
					<div className={styles.hamburgerHolder}>
						<div className={styles.hamburgerIcon} onMouseEnter={hideSubNav} onClick={openSideMenu}>
							<Hamburger color="#0000FF" />
						</div>
					</div>
				)}
			</nav>
			{/* {children} */}
			{displayMenu && <SideMenu hideMenu={() => setDisplayMenu(false)} />}
		</header>
	);
};

export default BoiHeader;
