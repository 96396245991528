import React, { useState, useRef } from "react";
import useMobile from "../Hooks/Mobile";

import axios, { Canceler } from "axios";
import { toast } from "react-toastify";
import Backend from "../../utils/Backend";
import { emailRegex } from "../../helper/constants";

import Spinner from "../Spinner";

import styles from "./styles.module.scss";

import { event } from "../../lib/gtag";
import useCancel from "../Hooks/CancelFetch";
import { useIsBoiWebsite } from "../Context/WebsiteType";

interface NewsletterProps {
	whiteVersion?: boolean;
	noHeader?: boolean;
	big?: boolean;
	className?: string;
	sideMenu?: boolean;
}

export default function Newsletter({ whiteVersion, noHeader, big, className, sideMenu }: NewsletterProps) {
	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("Thanks! You'll hear from us soon.");
	const emailHolderRef = useRef<HTMLDivElement>(null);

	const cancelRef = useRef<Canceler>();

	const smallDesktop = useMobile(1440);

	const isBoiWebsite = useIsBoiWebsite();

	const submissionAnimation = (firstTime = false) => {
		if (emailHolderRef && emailHolderRef.current) {
			emailHolderRef.current.classList.toggle(styles.hideEmail);
		}

		setTimeout(() => {
			setSubmitted(true);

			if (firstTime) {
				window.localStorage.setItem("isSubscribed", "yes");
			}
		}, 500);
	};

	useCancel(cancelRef.current);

	const submitEmail = () => {
		if (!emailRegex.test(email)) {
			toast.error("Please, provide correct e-mail address");
			return;
		}
		setLoading(true);

		isBoiWebsite ? event("subscribedToNewsletterBOI") : event("subscribedToNewsletter");
		const formType = isBoiWebsite ? "newsletter_boi" : "newsletter";
		const { promise, cancel } = Backend.subscribeToNewsletter(email, formType);

		cancelRef.current = cancel;

		promise
			.then((res) => {
				if (res.status === 201) submissionAnimation(true);
			})
			.catch((err) => {
				if (axios.isCancel(err)) return;

				if (err.response.status === 400) {
					let message: string = err.response.data.message;

					if (message.includes("The email address provided already exists.")) {
						message = "You're already subscribed to our newsletter.";
					}

					setMessage(message);
					submissionAnimation();
				} else {
					toast.error("Error occurred! Currently, we're experiencing technical issues, but we're working on that. Please, try again soon!");
					setLoading(false);
				}
			});
	};

	return (
		<div className={`${isBoiWebsite && !big ? styles.newsletterBoi : styles.newsletter} ${whiteVersion && (isBoiWebsite && !big ? styles.whiteVersionBoi : styles.whiteVersion)} ${className} ${big && !smallDesktop && styles.big}`}>
			{!noHeader && <h1 className="nav-button">{isBoiWebsite && !big ? "Nevo Newsletter" : "Newsletter"}</h1>}

			<div className={`${isBoiWebsite && !big ? styles.emailHolderBoi : styles.emailHolder} ${sideMenu && (isBoiWebsite && !big ? styles.sidemenuBoi : styles.sidemenu)} ${noHeader && (isBoiWebsite && !big ? styles.noHeaderBoi : styles.noHeader)} ${isBoiWebsite && !big ? styles.lightBoi : styles.light}`} ref={emailHolderRef}>
				{!submitted && (
					<>
						<input
							placeholder="Your email"
							className={`${isBoiWebsite && !big ? `${styles.inputBoi} ${styles.lightBoi}` : `${styles.input} ${styles.light}`}`}
							type="email"
							value={email}
							onChange={({ target }) => setEmail(target.value)}
							onKeyPress={(e) => {
								if (e.key === "Enter" && !loading && !submitted) {
									submitEmail();
								}
							}}
						/>
						<div>
							{loading ? (
								<div className={`${isBoiWebsite && !big ? styles.spinnerHolderBoi : styles.spinnerHolder} spinner-holder `}>
									<Spinner />
								</div>
							) : (
								<button
									className={`${sideMenu ? (isBoiWebsite && !big ? styles.sidemenuBoi : styles.sidemenu) : ""} ${
										isBoiWebsite && !big ? `${styles.buttonHolderBoi} dark-blue-button-boi` : `${styles.buttonHolder} blue-button`
									}`}
									onClick={submitEmail}
									>
								Subscribe
								</button>
							)}
						</div>
					</>
				)}

				{submitted && <p>{message}</p>}
			</div>
		</div>
	  )
}
