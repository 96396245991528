/* eslint-disable @next/next/no-img-element */
import React, { useRef } from "react";
import Link from "next/link";

import useMobile from "../Hooks/Mobile";
import { event } from "../../lib/gtag";

import SideMenuNav from "../SideMenuNav";
import Newsletter from "../Newsletter";
import SocialMedia from "../SocialMedia";

import styles from "./styles.module.scss";
import NevoLogo from "../../public/assets/images/NevoLogo";
import CloseIcon from "../../public/assets/icons/close.svg";
import CloseIconBoi from "../../public/assets/nevo-boi/close.svg";
import { useIsBoiWebsite } from "../Context/WebsiteType";
import { copyrightDisplayText } from "../../helper/functions/copyrightDisplayText";

interface iSideMenu {
	hideMenu: any;
}

const SideMenu = ({ hideMenu }: iSideMenu) => {
	const mobile = useMobile(567);
	const refMenu = useRef<HTMLDivElement>(null);

	const isBoiWebsite = useIsBoiWebsite();

	const onHideMenu = () => {
		if (refMenu && refMenu.current) {
			refMenu.current.classList.toggle(styles.sideMenuHide);
		}

		setTimeout(() => {
			if (refMenu && refMenu.current) {
				refMenu.current.style.display = "none";
			}

			const htmlDoc = document.children[0] as HTMLElement;

			if (htmlDoc.style) {
				htmlDoc.style.overflow = "";
				document.body.style.overflow = "";
			}
			hideMenu();
		}, 475);
	};

	const renderSideMenuHeader = () => {
		return (
			<div className={isBoiWebsite ? styles.sideMenuHeaderBoi : styles.sideMenuHeader}>
				{mobile && (
					<Link href="/" passHref>
						<div className={`${isBoiWebsite ? styles.nevoLogoBoi : styles.nevoLogo} nevo-logo`}>
							<NevoLogo color="#1A1C1F" />
						</div>
					</Link>
				)}

				<div onClick={onHideMenu} className={isBoiWebsite ? styles.closeIconContainerBoi : styles.closeIconContainer}>
					<img src={isBoiWebsite ? CloseIconBoi.src : CloseIcon.src} alt="close icon" />
				</div>
			</div>
		);
	};

	return (
		<>
			<div ref={refMenu} className={isBoiWebsite ? styles.sideMenuBoi : styles.sideMenu}>
				<div className={isBoiWebsite ? styles.sideMenuContentBoi : styles.sideMenuContent}>
					{renderSideMenuHeader()}

					<div className={isBoiWebsite ? styles.menuContentBoi : styles.menuContent}>
						<SideMenuNav hideMenu={onHideMenu} renderSideMenuHeader={renderSideMenuHeader} />

						{!isBoiWebsite && (
							<p className={`${isBoiWebsite ? styles.nevoChargingButtonBoi : styles.nevoChargingButton} nav-button`}>
								<a onClick={() => event("nevoAdvisorySideMenu")} href="https://nevoadvisory.ie" target="_blank" rel="noreferrer">
									Nevo Advisory
								</a>
							</p>
						)}

						<p
							className="nav-button"
							onClick={() => {
								event("ourMissionSideMenu");
								onHideMenu();
							}}
						>
							<Link href="/our-mission">About Nevo</Link>
						</p>

						{!isBoiWebsite && (
							<p
								className="nav-button"
								onClick={() => {
									event("partnersSideMenu");
									onHideMenu();
								}}
							>
								<Link href="/partners">Partners</Link>
							</p>
						)}

						{!isBoiWebsite && (
							<p
								className="nav-button"
								onClick={() => {
									event("contactUsSideMenu");
									onHideMenu();
								}}
							>
								<Link href="/contact-us" passHref>
									Contact Us
								</Link>
							</p>
						)}

						{isBoiWebsite && (
							<p>
								<a href="https://personalbanking.bankofireland.com/" target="_blank" rel="noopener noreferrer">
									<p
										className="nav-button"
										onClick={() => {
											event("backToBankOfIrelandSideMenu");
											onHideMenu();
										}}
									>
										Back to Bank of Ireland
									</p>
								</a>
							</p>
						)}

						{/* <p className="nav-button" 
              onClick={() => {
                event("feedbackSideMenu")
                onHideMenu();
              }}
            >
              <Link href="/feedback" passHref>Feedback</Link>
            </p> */}
					</div>

					<div className={isBoiWebsite ? styles.bottomContentBoi : styles.bottomContent}>
						<div className={styles.newsletterWrapper}>
							<Newsletter sideMenu />
						</div>
						
						{!isBoiWebsite && (
							<div className={isBoiWebsite ? styles.socialMediaBoi : styles.socialMedia}>
								<SocialMedia />
							</div>
						)}

						<div className={isBoiWebsite ? styles.copyrightBoi : styles.copyright}>
							<p className="copyright-mark">{copyrightDisplayText(isBoiWebsite)}</p>
						</div>
					</div>
				</div>
			</div>

			<div onClick={onHideMenu} className={"fading-cover " + `${isBoiWebsite ? styles.fadingCoverBoi : styles.fadingCover}`}>
				&nbsp;
			</div>
		</>
	);
};

export default SideMenu;
